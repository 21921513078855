@font-face {
    font-family: 'Ford-Antenna';
    src: url('./assets/temp/FordAntenna-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.customSelect .reactSelect__control {
    @apply !bg-transparent p-0 m-0 outline-none !border-none border-white !shadow-none;
}
.customSelect * {
    @apply !outline-none
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.countryInput input{
    @apply h-[54px] w-full border border-[#D0D0D0] rounded-md  placeholder:font-medium placeholder:text-sm placeholder:text-[#979A9C]  outline-none !important;
}
.selected-flag{
    @apply !w-[42px];
}
.mobileModal{
    @apply max-md:w-full max-md:max-w-full max-md:m-0 max-md:flex max-md:rounded-none max-md:items-center max-md:justify-center max-md:h-screen;
}

[dir="rtl"] .react-tel-input .flag-dropdown{
    @apply bg-transparent border-none right-[32px] !important;
}
[dir="rtl"]  .react-tel-input .form-control{
    padding-left: 15px !important;
}
[dir="rtl"] .react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
    background-color: transparent !important;
}